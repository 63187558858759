<template>
<div>
  <b-container fluid>
    <b-row class="my-5 text-center" v-if="busy">
      <b-col>
        <b-spinner label="Loading..."></b-spinner>
      </b-col>
    </b-row>
    <b-row class="mb-4" v-if="!busy">
      <b-col>
        <b-form inline class="mt-2">
          Policy area: <b-form-select class="ml-2 mr-4" v-model="areaSelected" :options="areaOptions"></b-form-select>
          Policy: <b-form-select class="ml-2 mr-4" v-model="categorySelected" :options="categoryOptions"></b-form-select>
          Measure: <b-form-select class="ml-2" v-model="mapSelected" :options="mapOptions"></b-form-select>
        </b-form>
      </b-col>
    </b-row>
    <b-row class="mb-4" v-if="!busy">
      <b-col xl="3" lg="4" md="6" sm="12">
        <b-container v-if="!busy && boxData.data.length > 0 && mapData.data.length > 0 && mapData.name" style="background: #FFFFFF 0% 0% no-repeat padding-box; box-shadow: 0px 12px 25px #00000029; border-radius: 2px; opacity: 1; height: 60vh; overflow-y: auto;  overflow-x: auto;">
          <b-row align-v="center" style="background: #ACEAFF 0% 0% no-repeat padding-box; opacity: 1; height: 45px;">
            <b-col>
              {{ mapData.name }}
            </b-col>
          </b-row>
          <b-row v-for="row in boxData.data" :key="'box-country'+row.name" @click="mapClick(row.name)" style="cursor: pointer;">
            <b-col>{{ row.name }}</b-col>
            <b-col class="text-right">{{ row.value }}</b-col>
          </b-row>
        </b-container>
      </b-col>
      <b-col xl="9" lg="8" md="6" sm="12" style="background: #FFFFFF 0% 0% no-repeat padding-box; box-shadow: 0px 12px 25px #00000029; border-radius: 2px; opacity: 1">
        <world-map-simple v-if="Array.isArray(mapData.data) && mapData.data.length > 0" :config="mapData" v-on:map-click="mapClick($event)" />
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import _ from 'lodash'
import * as echarts from 'echarts'
import theme from '@/libs/colorTheme'
import dataGetters from '@/libs/dataGetters'
import policysets from '@/libs/policysets'
import WorldMapSimple from '@/components/charts/v1/worldMapSimple.vue'

echarts.registerTheme('kpmg', theme)

/*
const chartsMap = [
  {
    dataGetter: 'directObjectMean',
    property: 'policydata',
    field: ['carbon_pricing_kpmg_impulse', 'carbon_pricing_kpmg_coverage', 'carbon_pricing_kpmg_direction', 'carbon_pricing_kpmg_horizon'],
    nameProperty: 'country',
    nameSeries: 'Composite impact',
    header: 'Carbon pricing KPMG impact',
    headerRecord: 'countries',
    categories: ['Overview'],
    area: 'Emission reduction'
  },
  {
    dataGetter: 'property',
    property: 'country',
    field: null,
    nameProperty: 'name',
    nameSeries: 'Initiatives',
    header: 'Number of initiatives',
    headerRecord: 'countries',
    categories: ['General'],
    area: 'Emission reduction'
  }
]
*/

function getRandomKey () {
  return Math.floor(Math.random() * 10000)
}

function delay (period) {
  return new Promise(resolve => {
    setTimeout(resolve, period)
  })
}

export default {
  name: 'PoliciesDashboard',
  components: {
    WorldMapSimple
  },
  computed: {
    areaOptions: function () {
      let results = []
      this.charts.forEach(x => {
        results.push(x.area)
      })
      results = _.uniq(results)
      results.sort()
      return results
    },
    boxData: function () {
      let result = {
        data: []
      }
      let dataYes = []
      let dataNo = []
      let dataNoMeasure = []
      if (!this.mapData.data) {
        return result
      }
      if (this.mapData.data.length === 0) {
        return result
      }
      _.each(this.mapData.data, (row) => {
        if (this.mapData.chart.dataGetter === 'directObjectBoolean') {
          if (row.name) {
            if (row.value === 1 || row.value === 0 || row.valuex === 'no measure') {
              if (row.value === 1) {
                dataYes.push({ name: row.name, value: 'Yes' })
              }
              if (row.value === 0) {
                dataNo.push({ name: row.name, value: 'No' })
              }
              if (row.valuex) {
                dataNoMeasure.push({ name: row.name, value: 'no measure' })
              }
            }
          }
        }
        if (this.mapData.chart.dataGetter === 'directObjectOptions') {
          if (row.name) {
            if (row.value === undefined) {
              dataNoMeasure.push({ name: row.name, value: 'no measure' })
            } else {
              result.data.push({ name: row.name, value: this.mapData.chart.fullField.options[row.value] })
            }
          }
        }
        if (this.mapData.chart.dataGetter === 'directObject') {
          if (row.name) {
            if (!Number.isFinite(row.value)) {
              dataNoMeasure.push({ name: row.name, value: 'no measure' })
            } else {
              result.data.push(row)
            }
          }
        }
      })
      if (result.data.length > 0) {
        result.data = _.orderBy(result.data, ['value'], ['desc'])
      }
      if (dataYes.length > 0) {
        dataYes = _.orderBy(dataYes, ['name'], ['asc'])
      }
      if (dataNo.length > 0) {
        dataNo = _.orderBy(dataNo, ['name'], ['asc'])
      }
      if (dataNoMeasure.length > 0) {
        dataNoMeasure = _.orderBy(dataNoMeasure, ['name'], ['asc'])
      }
      result.data = _.concat(result.data, dataYes, dataNo, dataNoMeasure)
      return result
    },
    categoryOptions: function () {
      let results = []
      this.charts.forEach(x => {
        if (x.area === this.areaSelected) {
          results.push({ name: x.policy, overview: x.overview })
        }
      })
      results = _.uniqBy(results, 'name')
      results = _.orderBy(results, ['overview', 'name'], ['desc', 'asc'])
      results = _.map(results, 'name')
      return results
    },
    charts: function () {
      const results = []
      _.each(policysets, (policy) => {
        _.each(policy.fields, (field) => {
          if (field.chart) {
            const p = _.find(this.policies, { 'id': policy.id })
            if (p) {
              results.push(
                {
                  dataGetter: field.dataGetter,
                  policyId: p.id,
                  property: 'policydata',
                  field: field.name,
                  fullField: field,
                  nameProperty: 'country',
                  nameSeries: field.label,
                  header: field.label,
                  headerRecord: 'countries',
                  policy: p.name,
                  overview: p.overview,
                  area: p.area
                })
            }
          }
        })
      })
      return results
    },
    countrySelected: {
      get () {
        return this.$store.state.countrySelected
      },
      set (payload) {
        this.$store.commit('setCountrySelected', payload)
      }
    },
    mapData: function () {
      let results = []
      const chart = this.charts.find(x => x.nameSeries === this.mapSelected && x.area === this.areaSelected && x.policy === this.categorySelected)
      if (chart) {
        const data = dataGetters[chart.dataGetter](this.rows, chart)
        results = {
          data: _.orderBy(data, ['value'], ['desc']),
          name: chart.nameSeries,
          header: chart.header,
          headerRecord: chart.headerRecord,
          chart: chart,
          key: getRandomKey()
        }
      }
      return results
    },
    mapOptions: function () {
      let results = []
      this.charts.forEach(x => {
        if (x.policy === this.categorySelected) {
          results.push(x.nameSeries)
        }
      })
      return results
    },
    policies: function () {
      let policies = []
      if (this.rows.length === 0) {
        return policies
      }
      _.each(this.rows, (row) => {
        policies.push({
          id: row.impactpolicy_id,
          name: row.impactpolicy.name,
          overview: row.impactpolicy.overview,
          area: row.impactpolicy.taxonomy[0]?.name
        })
      })
      policies = _.uniqBy(policies, 'id')
      return policies
    },
    rows: function () {
      return this.$store.state.initiativesPolicies
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  data () {
    return {
      busy: false,
      areaSelected: null,
      categorySelected: null,
      mapSelected: null,
      startSequence: true
    }
  },
  created: async function () {
    this.busy = true
    document.title = "Climate Policy and Regulatory Dashboard"
    if (window.localStorage.getItem('frmCloudAreaSelected')) {
      this.areaSelected = window.localStorage.getItem('frmCloudAreaSelected')
    } else {
      this.areaSelected = 'Emission reduction'
    }
    if (window.localStorage.getItem('frmCloudCategorySelected')) {
      this.categorySelected = window.localStorage.getItem('frmCloudCategorySelected')
    } else {
      this.categorySelected = 'Emission reduction overview'
    }
    if (window.localStorage.getItem('frmCloudMapSelected')) {
      this.mapSelected = window.localStorage.getItem('frmCloudMapSelected')
    } else {
      this.mapSelected = 'Horizon'
    }
    await delay(300)
    this.startSequence = false
    this.busy = false
  },
  methods: {
    mapClick: function (country) {
      const initiative = this.rows.find(x => x.impactpolicy.name === this.categorySelected && x.country === country)
      this.$router.push({ name: 'ImpactPoliciesInitiative', params: { id: initiative.id } })
    }
  },
  watch: {
    'areaSelected': function () {
      if (!this.startSequence) {
        window.localStorage.setItem('frmCloudAreaSelected', this.areaSelected)
        this.categorySelected = this.categoryOptions[0]
        this.mapSelected = this.mapOptions[0]
      }
    },
    'categorySelected': function () {
      if (!this.startSequence) {
        window.localStorage.setItem('frmCloudCategorySelected', this.categorySelected)
        this.mapSelected = this.mapOptions[0]
      }
    },
    'mapSelected': async function () {
      if (!this.startSequence) {
        window.localStorage.setItem('frmCloudMapSelected', this.mapSelected)
        this.busy = true
        await delay(100)
        this.busy = false
      }
    },
    'rows': async function () {
      this.busy = true
      // this.categorySelected = this.categoryOptions[0]
      // this.mapSelected = this.mapOptions[0]
      await delay(100)
      this.busy = false
    }
  }
}
</script>

<style>
</style>
